<template>
  <div class="forget-password flex flex-center">
    <div class="forget-password-box flex flex-center flex-column">
      <h1 class="text-center">忘记密码</h1>
      <div class="m-t-20 width-full">
        <el-steps :active="active" simple finish-status="success">
          <el-step title="身份认证"></el-step>
          <el-step title="重置密码"></el-step>
        </el-steps>
      </div>
      <div v-if="active === 0" class="m-t-20 width-full" v-loading="loading">
        <div class="m-b-10 width-full">
          <el-input
            class="width-full"
            v-model="formData.phone"
            maxlength="11"
            clearable
            placeholder="请输入手机号"
          ></el-input>
        </div>
        <div class="m-b-20">
          <SMSCode
            v-model="formData.smsCaptcha"
            :smsCaptcha="formData.smsCaptcha"
            :phone="formData.phone"
            type="resetPassword"
          />
        </div>
        <div class="m-b-20 flex flex-center font-size-14">
          <div @click="toLogin" class="cursor-pointer">又想起来了，去登录</div>
          <div class="flex1"></div>
        </div>
        <el-button class="width-full" type="primary" @click="next">下一步，重置密码</el-button>
      </div>
      <div v-if="active === 1" class="m-t-20 width-full" v-loading="loading">
        <div v-if="accountList.length > 1" class="m-b-10">
          <el-select class="width-full" v-model="formData.account" clearable placeholder="请选择账号">
            <el-option v-for="item in accountList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="m-b-10">
          <PassWord v-model="formData.newPassword" :password="formData.newPassword" />
        </div>
        <div class="m-b-10">
          <PassWord
            v-model="formData.confirmPassword"
            :password="formData.confirmPassword"
            placeholder="再次输入新登录密码"
          />
        </div>
        <div class="m-b-20 flex flex-center font-size-14">
          <div @click="toLogin" class="cursor-pointer">又想起来了，去登录</div>
          <div class="flex1"></div>
          <div @click="upActive" class="cursor-pointer">上一步</div>
        </div>
        <el-button class="width-full" type="primary" @click="confirm">确认重置并登录</el-button>
      </div>
      <div class="m-t-50 m-b-50 p-t-50 p-b-50"></div>
    </div>
    <StoreCom />
  </div>
</template>

<script>
import StoreCom from "@/components/StoreCom";
import SMSCode from "@/components/SMSCode";
import PassWord from "@/components/PassWord";
import { passwordValidate, passwordValidateTips, phoneValidate, phoneValidateTips } from "@/utils/validate";
import { loadAccountList } from "@/common/baseData";
import { checkPhone, updateResetPassword } from "@/api/base";

export default {
  name: "ForgetPassword",
  components: {
    StoreCom,
    SMSCode,
    PassWord
  },
  data() {
    return {
      desc: "忘记密码",
      loading: false,
      active: 0,
      formData: {
        phone: "",
        account: "",
        smsCaptcha: "",
        newPassword: "",
        confirmPassword: ""
      },
      accountList: []
    };
  },
  watch: {
    "formData.phone"() {
      this.getAccountList();
    }
  },
  methods: {
    async getAccountList() {
      this.accountList = await loadAccountList(this.formData.phone);
      if (this.accountList.length === 1) {
        this.formData.account = this.accountList[0];
      } else {
        this.formData.account = "";
      }
    },
    toLogin() {
      this.$router.replace({
        path: "/login"
      });
    },
    next() {
      if (!this.formData.phone) {
        this.$message.error("请输入手机号！");
        return;
      }
      if (!phoneValidate(this.formData.phone)) {
        this.$message.error(phoneValidateTips);
        return;
      }
      if (!this.formData.smsCaptcha) {
        this.$message.error("请输入验证码！");
        return;
      }
      if (this.formData.smsCaptcha.length !== 6) {
        this.$message.error("验证码位数不正确！");
        return;
      }
      const data = {
        phone: this.formData.phone,
        smsCaptcha: this.formData.smsCaptcha
      };
      this.loading = true;
      checkPhone(data)
        .then(() => {
          this.loading = false;
          this.active = 1;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "身份认证失败！");
        });
    },
    upActive() {
      this.active = 0;
    },
    confirm() {
      if (this.accountList.length > 1 && !this.formData.account) {
        this.$message.error("请选择账号！");
        return;
      }
      if (!this.formData.newPassword) {
        this.$message.error("请输入密码！");
        return;
      }
      if (!passwordValidate(this.formData.newPassword)) {
        this.$message.error(passwordValidateTips);
        return;
      }
      if (!this.formData.confirmPassword) {
        this.$message.error("请再次输入密码！");
        return;
      }
      if (!passwordValidate(this.formData.confirmPassword)) {
        this.$message.error(passwordValidateTips);
        return;
      }
      if (this.formData.newPassword !== this.formData.confirmPassword) {
        this.$message.error("两次密码不一致！");
        return;
      }
      const data = {
        phone: this.formData.phone, // 手机号
        account: this.formData.account, //  账号
        smsCaptcha: this.formData.smsCaptcha, //  短信验证码
        newPassword: this.formData.newPassword, //  新密码
        confirmPassword: this.formData.confirmPassword //  新密码
      };
      this.loading = true;
      updateResetPassword(data)
        .then(() => {
          this.loading = false;
          this.$message.success("密码修改成功");
          this.$router.replace({
            path: "/login"
          });
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "修改失败！");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.forget-password {
  width: 100%;
  height: 100%;
  min-width: 1024px;
  min-height: 650px;
  overflow: hidden;
  transform: scale(1);
  .forget-password-box {
    width: 350px;
  }
}
</style>
